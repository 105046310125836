import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  Typography,
  Box,
  Grid,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useValidateDomain from '../../hooks/useValidateDomain';
import ModalContext from '../../contexts/modal-context';
import updateDomain from '../../asyncActions/updateDomain';

import TextField from '../text-field/TextField';

import './Domains.scss';

const UpdateDomain = () => {
  const dispatch = useDispatch();
  const { user_id } = useSelector((state) => state.auth.auth);
  const domainUpdateData = useSelector((state) => state.domains.domain_id);
  const { isShowUpdateModal, setIsShowUpdateModal } = useContext(ModalContext);

  const {
    name,
    nameIsBlur,
    nameError,
    formIsValid,
    formIsSuccess,
    nameHandler,
    blurHandler,
    setName,
    setNameError,
    setFormIsValid,
    setFormIsSuccess,
  } = useValidateDomain();

  const notificationData = {
    isNotification: true,
    type: 'success',
    action: 'update_domain',
    message: `${domainUpdateData.domain_name} переименован`,
  };

  useEffect(() => {
    if (isShowUpdateModal) {
      setName(domainUpdateData.domain_name);
      setFormIsValid(true);
    }
  }, [
    isShowUpdateModal,
    domainUpdateData.domain_name,
    setName,
    setFormIsValid,
  ]);

  const onToggleModal = () => {
    return setIsShowUpdateModal(!isShowUpdateModal);
  };

  const submitForm = (e) => {
    e.preventDefault();

    setFormIsSuccess(!formIsSuccess);
    setFormIsValid(false);

    dispatch(updateDomain(user_id, domainUpdateData, name, notificationData));

    setIsShowUpdateModal(!isShowUpdateModal);

    setName('');
    setNameError(' ');
  };

  return (
    <Dialog open={isShowUpdateModal} className="MuiDialog-container">
      <Stack sx={{ gap: 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h3" component="h3" color="info.main">
            Изменение названия
          </Typography>
          <IconButton onClick={onToggleModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Box component="form" onSubmit={submitForm}>
          <TextField
            id="domainName"
            label="Введите новое название:"
            value={name}
            size="small"
            onBlur={blurHandler}
            onChange={nameHandler}
          />
          {nameIsBlur && nameError && (
            <Box className="validate-error" sx={{ top: '69px' }}>
              {nameError}
            </Box>
          )}
          <Grid container sx={{ pt: 3, gap: 2 }}>
            <Button
              variant="outlined"
              onClick={onToggleModal}
              sx={{ flex: '0 1 105px' }}
            >
              Отмена
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={formIsValid ? false : true}
              sx={{ flex: '0 1 105px' }}
            >
              Сохранить
            </Button>
          </Grid>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default UpdateDomain;
