import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Typography, Stack } from '@mui/material';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import Checkbox from '../checkbox/Checkbox';
import Search from './Search';
import Icon from '../Icon';

import { reducedUsersToTheirInitialSize } from '../../store/usersReducer';
import {
  treeUpdated,
  updateSelectedGroup,
  undoSelectedGroup,
  resetSelectedGroups,
  parseUserIdsForBackend,
  undoUsersOfSelectedGroup,
  selectAllUsersOfSelectedGroup,
  updateSelectedGroups,
  undoUsers,
  createArrayFromSelectedGroups,
} from '../../store/treeReducer';

import './Collections.scss';

import { fetchAllUsersOfSelectedGroup } from '../../asyncActions/fetchAllUsersOfSelectedGroup';

import {
  createUsersMap,
  createGroupTree,
  createTree,
} from '../../utils/makeTree';

const treeIcons = {
  check: <Icon id="check" width="20" height="20" />,
  halfCheck: <Icon id="halfcheck" width="20" height="20" />,
  uncheck: <Icon id="incheck" width="20" height="20" />,
  expandClose: <Icon id="close" width="14" height="14" />,
  expandOpen: <Icon id="expand" width="14" height="14" />,
};

const CollectionsConfigurate = ({ collectionName, checkedUsers }) => {
  const dispatch = useDispatch();

  const DOMAINS = useSelector((state) => state.domains.domains_list);
  const GROUPS = useSelector((state) => state.tree.groups);
  const USERS = useSelector((state) => state.users.users_list);
  const loadingUsers = useSelector((state) => state.users.loadingUsers);
  const filteredUsersFromApi = useSelector(
    (state) => state.users.filteredUsersFromApi
  );
  const auth = useSelector((state) => state.auth.auth);
  const tree = useSelector((state) => state.tree.tree);
  const selectedGroups = useSelector((state) => state.tree.selectedGroups);
  const arrayOfSelectedItems = useSelector(
    (state) => state.tree.arrayOfSelectedItems
  );

  // const observer = useRef();

  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [nodesFiltered, setNodesFiltered] = useState(tree);
  const [lastChildren, setLastChildren] = useState([]);
  const [breadcrumbs, setIsBreadcrumbs] = useState('');
  // const [pageCount, setPage] = useState(1);
  const [rawGroupId, setRawGroupId] = useState(0);
  const [groupFilterText, setGroupFilterText] = useState('');
  const [selectAllControlState, setSelectAllControlState] = useState(false);

  // This will roll back users array to its initial size
  // otherewise there'll be an error with duplicating ids
  useEffect(() => {
    dispatch(reducedUsersToTheirInitialSize());

    // Reset selected users when leave this component
    return () => {
      dispatch(resetSelectedGroups());
    };
  }, [dispatch]);

  useEffect(() => {
    const users =
      filteredUsersFromApi.length > 0 ? filteredUsersFromApi : USERS;

    const usersMap = createUsersMap(users);
    const groupsTree = createGroupTree(GROUPS, null, usersMap);
    const tree = createTree(DOMAINS, groupsTree);

    dispatch(treeUpdated(tree));
  }, [USERS, GROUPS, DOMAINS, filteredUsersFromApi, dispatch]);

  // This effect renders previously selected users in created collections
  useEffect(() => {
    checkedUsers &&
      GROUPS.forEach((group) => {
        // checking for a match of selected users in the tree
        const filterSelected = group.ad_users.filter((user) =>
          checkedUsers.includes(user)
        );

        filterSelected.forEach((userId) => {
          const key = `d${group.domain}-g${group.id}-u${userId}`;

          dispatch(updateSelectedGroups(key));
        });
      });
  }, [GROUPS, checkedUsers, tree, dispatch]);

  useEffect(() => {
    setNodesFiltered(tree);
  }, [tree, dispatch]);

  useEffect(() => {
    dispatch(createArrayFromSelectedGroups());
    dispatch(parseUserIdsForBackend());
  }, [selectedGroups, dispatch]);

  useEffect(() => {
    setSelectAllControlState(false);

    if (rawGroupId && selectedGroups[rawGroupId]) {
      const groupSize = Object.values(selectedGroups[rawGroupId]).length;

      if (groupSize >= lastChildren.length) {
        setSelectAllControlState(true);
      } else {
        setSelectAllControlState(false);
      }
    }
  }, [rawGroupId, selectedGroups, lastChildren, setSelectAllControlState]);

  // Tree filtering section

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);

    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
        -1 ||
      children.length
    ) {
      filtered.push({ ...node, ...(children.length && { children }) });
    }

    return filtered;
  };

  const filterTree = () => {
    if (!filterText) {
      setNodesFiltered(tree);

      return;
    }

    setNodesFiltered(tree.reduce(filterNodes, []));
  };

  const onFilterHandler = (e) => {
    setFilterText(e.target.value);

    e.target.value ? filterTree() : setNodesFiltered(tree);
  };

  const onGroupFilterHandler = (e) => {
    setGroupFilterText(e.target.value);
  };

  /////

  const onCheckHandler = (event, itemId) => {
    const checked = event.target.checked;

    if (itemId.includes('groupId')) {
      if (checked) {
        dispatch(updateSelectedGroup(itemId));
      } else {
        dispatch(undoSelectedGroup(itemId));
      }

      return;
    }

    if (checked) {
      dispatch(updateSelectedGroups(itemId));
    } else {
      dispatch(undoUsers(itemId));
    }
  };

  const selectAllHandler = (e) => {
    const isChecked = e.target.checked;

    setSelectAllControlState(isChecked);

    if (isChecked) {
      if (lastChildren.length > 40) {
        dispatch(fetchAllUsersOfSelectedGroup(auth.user_id, rawGroupId));
      } else {
        dispatch(selectAllUsersOfSelectedGroup(rawGroupId));
      }
    } else {
      dispatch(undoUsersOfSelectedGroup(rawGroupId));
    }
  };

  return (
    <>
      <Grid container className="breadcrumbs">
        <Grid item xs={12}>
          {`${
            collectionName ? collectionName : 'Название коллекции'
          } / ${breadcrumbs}`}
        </Grid>
      </Grid>
      <Grid container className="search-bar">
        <Grid item xs={6} className="tree-search">
          <Search
            value={filterText}
            placeholder="Поиск"
            sx={{ p: 0 }}
            onChange={onFilterHandler}
          />
        </Grid>
        <Grid item xs={6} className="group-search">
          <Search
            value={groupFilterText}
            userId={auth.user_id}
            groupId={rawGroupId}
            placeholder="Поиск по группе"
            onChange={onGroupFilterHandler}
          />
        </Grid>
      </Grid>
      <Grid container className="columns">
        <Grid item xs={6} className="tree">
          <CheckboxTree
            nodes={nodesFiltered}
            checked={arrayOfSelectedItems}
            expanded={expanded}
            disabled
            onExpand={setExpanded}
            showNodeIcon={false}
            expandOnClick
            onClick={(expanded) => {
              // Firstly reset group id. It's needed to separate same logic between groups
              // For example, Select all control relies on that, switching between groups should disable Select all control
              setRawGroupId(0);
              // Store a raw number of group id. Only a number 6 will be saved from {groupId: 'd1-g6'}
              const groupId = expanded.value.split('-groupId')[1];
              setRawGroupId(groupId);

              setIsBreadcrumbs(
                expanded.parent.title !== undefined ? expanded.parent.title : ''
              );

              setFilterText(expanded.label);

              if (expanded.children) {
                setLastChildren(expanded.children);
              }
            }}
            icons={treeIcons}
          />
        </Grid>
        <Grid item xs={6} className="users">
          <Checkbox
            disabled={!rawGroupId > 0}
            label="Выбрать все"
            // Didn't want to use extra div so put !important directive instead
            sx={{ pb: '8px !important' }}
            checked={selectAllControlState}
            onChange={(e) => selectAllHandler(e)}
          />

          {loadingUsers ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              gap={1}
              className="onscroll-loader"
            >
              <Icon id="loader" width="30" height="30" />
              <Typography variant="caption">
                Пожалуйста, подождите...
              </Typography>
            </Stack>
          ) : null}

          {lastChildren.length ? (
            <>
              {lastChildren.map((child, index) => {
                const isUserNode = child.hasOwnProperty('last_seen');
                const icon = isUserNode ? (
                  <Icon id="user" width="24" height="24" />
                ) : (
                  <Icon id="group" width="24" height="24" />
                );

                let isGroupSelected = false;

                // Here make sure that current child is a group with children
                if (child.children && child.children.length > 0) {
                  // Run check to make sure that every item from group's children has 'checked' state
                  // which means that group should be checked
                  const groupId = child.value.split('-groupId')[1];

                  if (groupId && selectedGroups[groupId]) {
                    const groupSize = Object.values(
                      selectedGroups[groupId]
                    ).length;

                    // Since we show 50 users per page as default, actual group size can be more than 50 users
                    isGroupSelected = groupSize >= child.children.length;
                  }
                }

                return (
                  <Box key={child.value} sx={{ pt: 1, pb: 1 }}>
                    <Checkbox
                      label={
                        <>
                          {/* This is a unique case where a padding between checkox and a label should be 16px */}
                          <Box />
                          {icon}
                          {child.label}
                        </>
                      }
                      checked={
                        isGroupSelected
                          ? isGroupSelected
                          : arrayOfSelectedItems.includes(child.value)
                      }
                      onChange={(event) => onCheckHandler(event, child.value)}
                    />
                  </Box>
                );
              })}
            </>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionsConfigurate;
