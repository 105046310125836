import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Grid } from '@mui/material';
// import jsCookie from "js-cookie";

import { getUsersListSuccess } from '../../store/usersReducer';

import Navbar from '../navbar/Navbar';
import { ProtectedRoutes } from '../ProtectedRoutes';
import Users from '../users/Users';
import Collections from '../collections/Collections';
import CollectionsCreate from '../collections/CollectionsCreate';
import CollectionsEditForm from '../collections/CollectionsEditForm';
import Domains from '../domains/Domains';
import { fetchAuthKeyAd } from '../../asyncActions/getAuthKeyAd';
import { fetchCabinetMenu } from '../../asyncActions/getCabinetMenu';
import { fetchProfilesList } from '../../asyncActions/getProfilesList';
import { fetchDomainsList } from '../../asyncActions/getDomainsList';
import { fetchGroupsList } from '../../asyncActions/getGroupsList';
import { fetchCollectionsList } from '../../asyncActions/getCollectionsList';
import AppBar from '../appbar/AppBar';

import styles from './App.module.scss';

import { domain, api } from '../../api/httpClient';

const App = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const { user_id, is_authenticated } = useSelector((state) => state.auth.auth);

  const domainData = useSelector((state) => state.domains.new_domain);
  const removeDomainData = useSelector((state) => state.domains.remove_domain);
  const updateDomainData = useSelector((state) => state.domains.update_domain);
  const collectionData = useSelector(
    (state) => state.collections.new_collection
  );
  const collectionPriority = useSelector(
    (state) => state.collections.collection_priority
  );
  const updateCollection = useSelector(
    (state) => state.collections.collection_update
  );
  const removeCollectionData = useSelector(
    (state) => state.collections.remove_collection
  );

  // csrf token is not used but will be, I guess, so I excluded it for while
  // const csrftoken = jsCookie.get('csrftoken');

  //get cabinet menu, profiles list, auth key active directory, users, users group lists
  useEffect(() => {
    if (is_authenticated) {
      dispatch(fetchCabinetMenu(user_id));
      dispatch(fetchProfilesList(user_id));
      dispatch(fetchGroupsList(user_id));

      const triggerLoader = true;
      dispatch(fetchCollectionsList(user_id, triggerLoader));
    }
  }, [user_id, is_authenticated, dispatch]);

  // There can be a bug in Collections, when user has more than 1 page of users
  // and some groups with users in domains can be empty, just because we loaded only one page.
  useEffect(() => {
    if (is_authenticated) {
      // Firstly, load first page with users.
      api
        .get(
          `${domain}/ad/rest_api/v1/users/${user_id}/ad_users_with_profiles/?page=1`
        )
        .then((res) => {
          // Save total pages that user has.
          setTotalPages(res.data.total_pages);
          setUsers((prevState) => [...prevState, ...res.data.results]);
        });
    }
  }, [user_id, is_authenticated, dispatch]);

  useEffect(() => {
    // In this effect, the app loads all users to provide proper work of Collections.
    if (totalPages > 1) {
      const requests = [];

      for (let i = 1; i < totalPages; i++) {
        const index = i + 1;
        const usersRequest = api.get(
          `/ad/rest_api/v1/users/${user_id}/ad_users_with_profiles/?page=${index}`
        );

        requests.push(usersRequest);
      }

      Promise.all(requests).then((res) => {
        const arr = res.reduce((acc, current) => {
          acc.push(...current.data.results);

          return acc;
        }, []);

        setUsers((prevState) => [...prevState, ...arr]);
      });
    }
  }, [totalPages, user_id, dispatch]);

  useEffect(() => {
    // Finally, update Store with all users.
    dispatch(getUsersListSuccess(users));
  }, [users, dispatch]);

  //get dmains list
  useEffect(() => {
    is_authenticated && dispatch(fetchDomainsList(user_id));
  }, [
    user_id,
    is_authenticated,
    domainData,
    removeDomainData,
    updateDomainData,
    dispatch,
  ]);

  //get collections list
  useEffect(() => {
    const triggerLoader = true;
    is_authenticated && dispatch(fetchCollectionsList(user_id, triggerLoader));
  }, [
    user_id,
    is_authenticated,
    collectionData,
    updateCollection,
    removeCollectionData,
    dispatch,
  ]);

  useEffect(() => {
    if (is_authenticated) {
      const triggerLoader = false;
      dispatch(fetchCollectionsList(user_id, triggerLoader));
    }
  }, [user_id, is_authenticated, collectionPriority, dispatch]);

  useEffect(() => {
    if (user_id) {
      dispatch(fetchAuthKeyAd(user_id));
    }
  }, [user_id, dispatch]);

  return (
    <Grid container className={styles['app']}>
      {is_authenticated && (
        <>
          <AppBar />
          <Navbar />
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route index path="/" element={<Navigate to="/users" />} />
              <Route path="/users" element={<Users />} />
              <Route path="/collections" element={<Collections />} />
              <Route
                path="/collections/create"
                element={<CollectionsCreate />}
              />
              <Route
                path="/collections/:id"
                element={<CollectionsEditForm />}
              />
              <Route path="/domains" element={<Domains />} />
              <Route path="*" element={<p>404</p>} />
            </Route>
          </Routes>
        </>
      )}
    </Grid>
  );
};

export default App;
