import {
  getCollectionsList,
  getCollectionsListSuccess,
  getCollectionsListFailure,
} from '../store/collectionsReducer';
import { api } from '../api/httpClient';

export const fetchCollectionsList = (userId, triggerLoader = false) => {
  return function (dispatch) {
    if (triggerLoader) {
      dispatch(getCollectionsList());
    }

    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${userId}/collections/`
      )
      .then((response) => {
        dispatch(getCollectionsListSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getCollectionsListFailure());
      });
  };
};
